import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Front Squats 4×2\\@85% 1RM`}</p>
    <p>{`Glute Ham Raise 4×4 `}<em parentName="p">{`or`}</em>{` Single Leg BLB 4×4/leg`}</p>
    <p>{`then, `}<strong parentName="p">{`“Larry”`}</strong></p>
    <p>{`21-18-15-12-9-6-3 reps each:`}</p>
    <p>{`Front Squats (115/75)`}</p>
    <p>{`Bar Facing Burpees`}</p>
    <p>{`*`}{`200M Sandbag Carry after each set. (80/50`}{`#`}{` bag)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      